import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";

import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import ProjectsSettingsWrapper from "../ProjectsSettingsWrapper";
import { useForm, Controller } from "react-hook-form";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import ChangeImageModal from "../Modals/ChangeImageModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getProjectDetatis,
  updateProjectDetails,
} from "../../../Core/redux/api/projectAPI";
import dayjs from "dayjs";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import DiscardModal from "../Modals/DiscardModal";
import usePermission from "../../../Core/hooks/usePermission";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useGetProjectWeeks } from "../../../Core/redux/ReactQueryHooksV3/userTimeEstimatesAPI";
import { useQueryClient } from "react-query";
import EstimatesMissingModal from "../../TimeEstimates/Modals/EstimatesMissingModal";
import { deleteWeeks } from "../../../Core/redux/api/timeEstimatesAPI";

const Border = () => (
  <div className="border-solid border-gray-200 relative w-full h-px  border-t  rounded-none" />
);

function General() {
  const { projectDetails } = useAppSelector((state) => state.overview);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [changeImageModal, setChangeImageModal] = useState({
    visible: false,
    data: { image: "", color: "", title: "" },
    action: "changeProjectImage",
  });
  const [dateChangeModalOne, setDateChangeModalOne] = useState(false);
  const [dateChangeModalTwo, setDateChangeModalTwo] = useState(false);
  const [dateChangeModalThree, setDateChangeModalThree] = useState(false);

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    values: {
      projectName: projectDetails.title,
      projectImage: projectDetails.projectImage,
      projectColor: projectDetails.colorCode,
      startDate: projectDetails.startDate || null,
      endDate: projectDetails.endDate || null,
      clientName: projectDetails.clientName,
      clientImage: projectDetails.clientImage,
      clientColor: projectDetails.clientColor,
      clientLocation: projectDetails.clientLocation,
      projectThemeColor: projectDetails.projectThemeColor,
    },
  });

  const { data: weeksData } = useGetProjectWeeks(projectDetails?.id);
  // console.log(weeksData, "weeksData");
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();

  const calculateWeeks = (data) => {
    const { startDate, endDate } = data;

    // Check if dates are changed
    const isStartDateChanged =
      new Date(startDate).getTime() !==
      new Date(projectDetails.startDate).getTime();
    const isEndDateChanged =
      new Date(endDate).getTime() !==
      new Date(projectDetails.endDate).getTime();

    if (isStartDateChanged || isEndDateChanged) {
      const hasConflicts = weeksData.some((week) => {
        // Check if any existing week falls outside the new range or overlaps incorrectly
        const weekStart = new Date(week.startDate).getTime();
        const weekEnd = new Date(week.endDate).getTime();
        return (
          weekStart < new Date(startDate).getTime() ||
          weekEnd > new Date(endDate).getTime()
        );
      });

      if (hasConflicts) {
        setDateChangeModalTwo(true);
        return;
      } else {
        setDateChangeModalOne(true);
        return;
      }
    }
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (data) => {
    const projectId = searchParams.get("projectId");
    if (!projectId) return;

    // Proceed with project update if no date changes or conflicts
    setShowDiscardModal(false);
    await dispatch(
      updateProjectDetails({
        id: projectId,
        title: data.projectName,
        startDate: data.startDate,
        endDate: data.endDate,
        projectImage: data.projectImage,
        projectColor: data.projectColor,
        clientName: data.clientName,
        clientImage: data.clientImage,
        clientColor: data.clientColor,
        clientLocation: data.clientLocation,
        projectThemeColor: data.projectThemeColor,
      })
    );

    await dispatch(getProjectDetatis({ projectId }));
    reset({}, { keepValues: true });

    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: "Project details are updated",
            subtitle: "Saved changes to your project",
            description: "",
          },
        },
      })
    );

    queryClient.refetchQueries(["tenantInsights"], {
      active: false,
    });
    navigate(-1);
  };

  useEffect(() => {
    // reset({
    //   projectName:projectDetails.title,
    //   projectImage:projectDetails.projectImage,
    //   projectColor:projectDetails
    // })
    setValue("projectName", projectDetails.title);
    setValue("projectImage", projectDetails.projectImage);
    setValue("projectColor", projectDetails.colorCode);
    setValue("startDate", projectDetails.startDate);
    setValue("endDate", projectDetails.endDate);
    setValue("clientName", projectDetails.clientName);
    setValue("clientImage", projectDetails.clientImage);
    setValue("clientColor", projectDetails.clientColor);
    setValue("clientLocation", projectDetails.clientLocation);
    setValue("projectThemeColor", projectDetails.projectThemeColor);
  }, [projectDetails]);

  watch(["projectImage", "projectImage", "projectName", "projectThemeColor"]);

  const getDeletedWeeks = () => {
    const { startDate, endDate } = getValues();
    const deletedWeeks = weeksData
      .filter((week) => week.startDate < startDate || week.endDate > endDate)
      .map((week) => ({ id: week.id }));

    return deletedWeeks;
  };

  const handleCancel = () => {
    if (!formState.isDirty) {
      const url = localStorage.getItem("settingsRedirectUrl");
      if (url) {
        navigate(url);
      } else {
        navigate(-1);
      }
    } else {
      setShowDiscardModal(true);
    }
  };

  const { hasPermission } = usePermission("1");
  let colorType = "";
  if (changeImageModal.action === "changeProjectImage") {
    colorType = "projectColor";
  } else {
    colorType = "clientColor";
  }
  console.log(colorType, "cmi", weeksData);
  return (
    <div>
      {hasPermission && (
        <div className="flex fixed top-2 right-[60px] justify-center items-center gap-x-3">
          <CustomButton
            text="Cancel"
            className="bg-white border border-gray-200"
            height="30px"
            onClick={() => handleCancel()}
          />
          <CustomButton
            text="Save Changes"
            height="30px"
            onClick={handleSubmit(calculateWeeks)}
            disabled={!formState.isDirty}
          />
        </div>
      )}

      <PageTitle
        title={"General"}
        subtext={"Manage your project and client information."}
      />
      <ProjectsSettingsWrapper>
        <div className="flex flex-col gap-y-6 mt-6 pb-40">
          <div className="out-300-14 text-gray-500">Project info</div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">Logo</p>
            <CustomAvatar
              size={64}
              title={getValues("projectName")}
              whiteText
              color={getValues("projectColor")}
              src={getValues("projectImage")}
              fontSize={"24px"}
            />
            <button
              onClick={() =>
                setChangeImageModal({
                  visible: true,
                  data: {
                    image: getValues("projectImage"),
                    color: getValues("projectColor"),
                    title: getValues("projectName"),
                  },
                  action: "changeProjectImage",
                })
              }
              className="out-500-14 mt-3 text-primary-700"
            >
              Change Image
            </button>
          </div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">Project name</p>
            <Controller
              control={control}
              name="projectName"
              rules={{
                required: "Please Add the Project Name",
                maxLength: {
                  value: 45,
                  message: "Maximum 45 characters allowed",
                },
              }}
              render={({ field }) => (
                <CustomInputBox
                  {...field}
                  placeholder="Project Name"
                  rootStyle={{ width: "50%" }}
                  formState={formState}
                />
              )}
            />
          </div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">Start date</p>
            <Controller
              control={control}
              name="startDate"
              rules={{
                required: "Please add the start date",
                validate: (startDate) => {
                  const endDate = getValues("endDate");
                  return (
                    !endDate ||
                    new Date(startDate) <= new Date(endDate) ||
                    "Start date cannot be after the end date"
                  );
                },
              }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  placeholder="Start Date"
                  className="w-[50%] bg-transparent"
                  format="DD MMMM YYYY"
                  formState={formState}
                  // rootStyle={{ width: "50%" }}
                />
              )}
            />
          </div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">End date</p>
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: "Please add the end date",
                validate: (endDate) => {
                  const startDate = getValues("startDate");
                  return (
                    !startDate ||
                    new Date(endDate) >= new Date(startDate) ||
                    "End date cannot be before the start date"
                  );
                },
              }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  placeholder="End Date"
                  format="DD MMMM YYYY"
                  className="w-[50%] bg-transparent"
                  formState={formState}
                  // rootStyle={{ width: "50%" }}
                />
              )}
            />
          </div>
          <Border />
          <div className="out-300-14 text-gray-500">Client info</div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">Logo</p>
            <CustomAvatar
              size={64}
              title={getValues("clientName")}
              whiteText
              color={getValues("clientColor")}
              src={getValues("clientImage")}
              fontSize={"24px"}
            />
            <button
              onClick={() =>
                setChangeImageModal({
                  visible: true,
                  data: {
                    image: getValues("clientImage"),
                    color: getValues("clientColor"),
                    title: getValues("clientName"),
                  },
                  action: "changeClientImage",
                })
              }
              className="out-500-14 mt-3 text-primary-700"
            >
              Change Image
            </button>
          </div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">Client name</p>
            <Controller
              control={control}
              name="clientName"
              rules={{ required: "Please Add the client Name" }}
              render={({ field }) => (
                <CustomInputBox
                  {...field}
                  placeholder="Client Name"
                  rootStyle={{ width: "50%" }}
                  formState={formState}
                />
              )}
            />
          </div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">Based in</p>
            <Controller
              control={control}
              name="clientLocation"
              rules={{ required: "Please Add the Location" }}
              render={({ field }) => (
                <CustomInputBox
                  {...field}
                  placeholder="Based in"
                  rootStyle={{ width: "50%" }}
                  formState={formState}
                />
              )}
            />
          </div>
          <div>
            <p className="out-500-14 text-gray-700 pb-[6px]">
              Current time & timezone
            </p>

            <div className="py-[5px] px-2.5 bg-gray-50 rounded border border-gray-200 out-300-14 text-gray-500 w-1/2">{`${dayjs().format(
              "hh:mm a"
            )}, ${dayjs.tz.guess()}`}</div>
            <p className="out-300-12 text-gray-500 mt-1">
              Updates automatically based on your current location.
            </p>
          </div>
          <Border />

          <div className="out-300-14 text-gray-500">Event theme</div>

          <CustomColorPicker
            placement="bottomLeft"
            onColorChange={(color) => {
              setValue("projectThemeColor", color, { shouldDirty: true });
            }}
          >
            <button className="flex ">
              <div
                className="h-5 w-5 rounded-full mr-2 ml-2.5"
                style={{ backgroundColor: getValues("projectThemeColor") }}
              ></div>
              <div className=" text-gray-500 out-500-12 mr-2">Change</div>
              <ChevronIcon className="text-gray-500" />
            </button>
          </CustomColorPicker>
        </div>
      </ProjectsSettingsWrapper>
      {changeImageModal.visible && (
        <ChangeImageModal
          visible={changeImageModal.visible}
          onClose={() =>
            setChangeImageModal({
              visible: false,
              data: { image: "", color: "", title: "" },
              action: "changeProjectImage",
            })
          }
          colorType={colorType}
          data={changeImageModal.data}
          onOk={(image, color) => {
            if (changeImageModal.action === "changeProjectImage") {
              setValue("projectImage", image, { shouldDirty: true });
              setValue("projectColor", color, { shouldDirty: true });
            } else {
              setValue("clientImage", image, { shouldDirty: true });
              setValue("clientColor", color, { shouldDirty: true });
            }
          }}
        />
      )}

      <DiscardModal
        visible={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        onDiscard={() => {
          reset({}, { keepValues: true });
          setShowDiscardModal(false);
        }}
        onSave={handleSubmit(calculateWeeks)}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalOne(false)}
        title="Week numbers may vary!"
        description="You are changing the dates of this project. This will affect the project week numbers & existing time estimates. Are you sure you want to proceed?"
        buttonOneText="Cancel"
        buttonTwoText="Yes, proceed"
        onButtonOneClick={() => {
          setDateChangeModalOne(false);
        }}
        onButtonTwoClick={async () => {
          handleSubmit(onSubmit)();
          setDateChangeModalOne(false);
        }}
        visible={dateChangeModalOne}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalTwo(false)}
        title="Week numbers may vary!"
        description="You are changing the dates of this project. This will affect the project week numbers. <br/> <br/> Do you want to delete existing estimates/weeks or retain existing estimates & delete weeks manually?"
        buttonOneText="Delete Estimates"
        buttonTwoText="Retain Estimates"
        onButtonOneClick={async () => {
          setDateChangeModalThree(true);
          setDateChangeModalTwo(false);
        }}
        onButtonTwoClick={async () => {
          setDateChangeModalTwo(false);
          handleSubmit(onSubmit)();
        }}
        visible={dateChangeModalTwo}
      />

      <EstimatesMissingModal
        onClose={() => setDateChangeModalThree(false)}
        title="Week numbers may vary!"
        description="This action will automatically delete the estimates for the week(s) no longer in the project duration. Project week numbers will be adjusted accordingly."
        buttonOneText="Cancel"
        buttonTwoText="Confirm"
        onButtonOneClick={async () => {
          setDateChangeModalThree(false);
        }}
        onButtonTwoClick={async () => {
          try {
            const projectId = projectDetails.id;
            const deletedWeeks = getDeletedWeeks();

            await dispatch(deleteWeeks({ projectId, deletedWeeks })).unwrap();
            queryClient.invalidateQueries(["projectWeeks", projectId]);
            handleSubmit(onSubmit)();

            console.log("Weeks deleted successfully");
          } catch (error) {
            console.error("Failed to delete weeks:", error);
          } finally {
            setDateChangeModalThree(false);
          }
        }}
        visible={dateChangeModalThree}
      />
    </div>
  );
}

export default General;
