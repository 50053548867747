import React, { useEffect, useState } from "react";
import TimeEstimatesLayout from "./TimeEstimatesLayout";
import TimeEstimatesMenu from "./TimeEstimatesMenu";

import { ProjectCount, ProjectContainer } from "./TimeEstimatesReusable";
import { Spin } from "antd";
import { useAppSelector } from "../../Core/redux/hooks";
import { useGetProjectList } from "../../Core/redux/ReactQueryHooksV3/useProjectAPI";

const TimeEstimatesScreen = () => {
  const { user } = useAppSelector((state) => state.userDetails);

  const { data: projectsData, isLoading: isLoading } = useGetProjectList(
    user.id
  );
  const projects = projectsData || [];

  const [search, setSearch] = useState("");
  const filteredProjects = projects.filter((project) =>
    project.title.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <TimeEstimatesLayout>
      <TimeEstimatesMenu search={search} setSearch={setSearch} />
      <Spin spinning={isLoading}>
        <div className=" h-[calc(100vh-157px)] bg-gray-50 overflow-y-scroll">
          <div className="flex flex-col justify-start gap-4 px-6 py-4 ">
            <ProjectCount count={filteredProjects.length} />

            <div className="flex flex-wrap">
              {filteredProjects.map((project) => (
                <ProjectContainer key={project.id} project={project} />
              ))}
            </div>
          </div>
        </div>
      </Spin>
    </TimeEstimatesLayout>
  );
};

export default TimeEstimatesScreen;
