import React, { useEffect } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";

import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";

import ClockIcon from "../../../Core/svgV2/Clock";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import {
  createSprint,
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { useQueryClient } from "react-query";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

const EditSprintModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const { projectId } = useParams();
  const { editSprintModal, phases, filterGoalParameter } = useAppSelector(
    (state) => state.schedule
  );
  const { user } = useAppSelector((state) => state.userDetails);

  const { projectDetails } = useAppSelector((state) => state.overview);

  const { formState, control, reset, handleSubmit, setValue } = useForm({
    values: {
      sprintName: "",
      startDate: null,
      endDate: null,
    },
    defaultValues: {
      sprintName: editSprintModal.sprint ? editSprintModal.sprint.name : "",
      startDate: editSprintModal.sprint
        ? dayjs(editSprintModal.sprint.startDate)
        : "",
      endDate: editSprintModal.sprint
        ? dayjs(editSprintModal.sprint.endDate)
        : "",
    },
  });

  useEffect(() => {
    {
      if (
        editSprintModal.visible &&
        (editSprintModal.mode == "new" || editSprintModal.mode === "start")
      ) {
        const onGoingPhases = phases.filter(({ isOngoing }) => isOngoing === 1);

        if (
          projectDetails.hasPhases &&
          onGoingPhases.length === 0 &&
          (editSprintModal.phaseId === "" || !editSprintModal.phaseId)
        ) {
          message.error({
            content: (
              <span className="out-400-14 text-gray-900 cursor-pointer">
                No ongoing phases. Please start the phase first.
              </span>
            ),
            className: "custom-message",
            style: {
              borderRadius: "10px",
            },
          });
          reset();
          onClose();
        }
      }
      if (
        editSprintModal.visible &&
        (editSprintModal.mode === "edit" || editSprintModal.mode === "start")
      ) {
        setValue(
          "sprintName",
          editSprintModal.sprint ? editSprintModal.sprint.name : ""
        );
        setValue(
          "startDate",
          editSprintModal.sprint ? dayjs(editSprintModal.sprint.startDate) : ""
        );
        setValue(
          "endDate",
          editSprintModal.sprint ? dayjs(editSprintModal.sprint.endDate) : ""
        );
      }
    }
  }, [editSprintModal]);

  const onSubmit = async (data) => {
    try {
      if (editSprintModal.mode === "edit") {
        await dispatch(
          updateSprint({
            sprintId: editSprintModal.sprint.id,
            title: data.sprintName,
            startDate: data.startDate.format("YYYY-MM-DD"),
            endDate: data.endDate.format("YYYY-MM-DD"),
          })
        );
      }
      if (editSprintModal.mode === "start") {
        const onGoingPhases = phases.filter(({ isOngoing }) => isOngoing === 1);
        console.log(
          projectDetails.hasPhases,
          onGoingPhases.length === 0,
          editSprintModal.phaseId === "" || !editSprintModal.phaseId,
          projectDetails.hasPhases &&
            onGoingPhases.length === 0 &&
            (editSprintModal.phaseId === "" || !editSprintModal.phaseId),
          "condition check"
        );
        if (
          projectDetails.hasPhases &&
          onGoingPhases.length === 0 &&
          (editSprintModal.phaseId === "" || !editSprintModal.phaseId)
        ) {
          message.error({
            content: (
              <span className="out-400-14 text-gray-900 cursor-pointer">
                No ongoing phases. Please start the phase first.
              </span>
            ),
            className: "custom-message",
            style: {
              borderRadius: "10px",
            },
          });
          reset();
          onClose();
        } else {
          if (dayjs(data.startDate).isAfter(dayjs())) {
            message.error({
              content: (
                <span className="out-400-14 text-gray-900 cursor-pointer">
                  To start a sprint set the start date to be on or before today
                </span>
              ),
              className: "custom-message",
              style: {
                borderRadius: "10px",
              },
            });
          } else {
            await dispatch(
              updateSprint({
                startDate: data.startDate.format("YYYY-MM-DD"),
                endDate: data.endDate.format("YYYY-MM-DD"),
                status: 2,
                isOngoing: 1,
                isCompleted: 0,
                sprintId: editSprintModal.sprint.id,
                startSprint: true,
                phases: phases
                  .filter(
                    (p) =>
                      p.isOngoing === 1 &&
                      +editSprintModal.sprint.phaseId != +p.id
                  )
                  .map((p) => p.id),
              })
            );
            queryClient.refetchQueries(["activeSprint", projectId]);
            dispatch(
              updateSchedule({
                key: "filterGoalParameter",
                value: {
                  ...filterGoalParameter,
                  sprintIds: [editSprintModal.sprint.id],
                },
              })
            );
          }
        }
      }
      if (editSprintModal.mode == "new") {
        const onGoingPhases = phases.filter(({ isOngoing }) => isOngoing === 1);

        if (
          projectDetails.hasPhases &&
          onGoingPhases.length === 0 &&
          (editSprintModal.phaseId === "" || !editSprintModal.phaseId)
        ) {
          message.error({
            content: (
              <span className="out-400-14 text-gray-900 cursor-pointer">
                No ongoing phases. Please start the phase first.
              </span>
            ),
            className: "custom-message",
            style: {
              borderRadius: "10px",
            },
          });

          return;
        }
        await dispatch(
          createSprint({
            sprints: [
              {
                title: data.sprintName,
                startDate: data.startDate.format("YYYY-MM-DD"),
                endDate: data.endDate.format("YYYY-MM-DD"),
              },
            ],

            projectId: projectId,
            phaseId: editSprintModal.phaseId,
            isActive: 1,
            isOngoing: 0,

            isNewSprint: 0,
            senderId: user.id,
            hasPhases: false,
          })
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      const sprintsGetPayload: any = { projectId };
      // if (editSprintModal.mode === "new") {
      //   sprintsGetPayload.phaseId = editSprintModal.phaseId;
      // }
      dispatch(getSprintsByPhase(sprintsGetPayload));
      queryClient.invalidateQueries(["sprintsByPhase", projectId]);
      reset();
      onClose();
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="py-3 px-4">
          <div>
            <h3 className="out-500-14 text-black capitalize">
              {editSprintModal.mode} Sprint
            </h3>

            <div className="pt-4">
              <Controller
                control={control}
                name="sprintName"
                rules={{ required: "Please Add the Sprint Name" }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    className="w-full"
                    placeholder="Sprint Name"
                    formState={formState}
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                control={control}
                name="startDate"
                rules={{ required: "Please Add Start Date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full  bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="Start date"
                    suffixIcon={<></>}
                    formState={formState}
                    disabledDate={(current) =>
                      dayjs(current).isBefore(projectDetails.startDate) ||
                      current.isAfter(projectDetails.endDate)
                    }
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                control={control}
                name="endDate"
                rules={{ required: "Please Add End Date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full  bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="End date"
                    suffixIcon={<></>}
                    formState={formState}
                    disabledDate={(current) =>
                      dayjs(current).isBefore(projectDetails.startDate) ||
                      current.isAfter(projectDetails.endDate)
                    }
                  />
                )}
              />
            </div>
            <div className="mt-4">
              {projectDetails.hasPhases && (
                <h4 className="out-500-14 text-black">Ongoing phases</h4>
              )}

              {phases
                .filter(({ isOngoing }) => isOngoing === 1)
                .map((item) => (
                  <div className="mt-3 flex justify-between" key={item.id}>
                    <div className="flex ">
                      <PhaseIcon
                        phaseId={+item.phaseId}
                        height="24"
                        width="24"
                      />
                      <p className="out-300-14 text-gray-900 ml-2">
                        {item.phases}
                      </p>
                    </div>
                    <div className="flex ">
                      <ClockIcon className="text-gray-500" />
                      <p className="out-300-14 text-gray-500 ml-2">
                        {dayjs(item.startDate).format("DD MMM")} -{" "}
                        {dayjs(item.endDate).format("DD MMM")}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text={
                editSprintModal.mode == "new"
                  ? "Create"
                  : editSprintModal.mode === "start"
                  ? "Start"
                  : "Save"
              }
              height="30px"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default EditSprintModal;
