// export function createNotificationSound() {
//   //   const sound = document.createElement("audio");
//   //   sound.style.cssText += `display: none;`;
//   //   sound.id = "notifierSound";
//   //   sound.src = "https://dxstmhyqfqr1o.cloudfront.net/sound/SendBird-default.mp3";
//   //   return sound;
//   const RINGTONE_MP3 =
//     "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/relax_message_tone_laxjshwy.mp3-1669443895535.mp3?download=1";
//   const RINGTONE_MR4 =
//     "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/relax_message_tone_laxjshwz.m4r-1669443895576.m4r?download=1";
//   const RINGTONE_OGG =
//     "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/relax_message_tone_laxjshx0.ogg-1669443895557.ogg?download=1";
//   const audio = new Audio(RINGTONE_OGG);
//   audio.play();
//   audio.remove();
// }

import axios from "axios";

import { extensions } from "./fileExtentions";
import { storeDataConsumed } from "./dcfUtils";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

export function debounce<F extends (...args: any[]) => void>(
  fn: F,
  ms: number,
  shouldRunFirst = true,
  shouldRunLast = true
) {
  let waitingTimeout: number | undefined;

  return (...args: Parameters<F>) => {
    if (waitingTimeout) {
      clearTimeout(waitingTimeout);
      waitingTimeout = undefined;
    } else if (shouldRunFirst) {
      return fn(...args);
    }

    // eslint-disable-next-line no-restricted-globals
    waitingTimeout = self.setTimeout(() => {
      if (shouldRunLast) {
        return fn(...args);
      }

      waitingTimeout = undefined;
    }, ms);
  };
}

export async function playNotifySound() {
  const audio = new Audio("/Audio/notification2.mp3");

  audio.setAttribute("mozaudiochannel", "notification");
  console.log("audio [PUSH]");
  try {
    await audio.play();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("[PUSH] Unable to play notification sound");
  }
}

export const playNotifySoundDebounced = debounce(
  playNotifySound,
  1000,
  true,
  false
);

export function xssEscape(target) {
  if (typeof target === "string") {
    return target
      .split("&")
      .join("&amp;")
      .split("#")
      .join("&#35;")
      .split("<")
      .join("&lt;")
      .split(">")
      .join("&gt;")
      .split('"')
      .join("&quot;")
      .split("'")
      .join("&apos;")
      .split("+")
      .join("&#43;")
      .split("-")
      .join("&#45;")
      .split("(")
      .join("&#40;")
      .split(")")
      .join("&#41;")
      .split("%")
      .join("&#37;");
  } else {
    return target;
  }
}

// TODO: nothing
export async function downloadFiles(fileObjects, folderName) {
  if (fileObjects.length === 1) {
    const file = fileObjects[0];

    try {
      const response = await axios.get(file.url, { responseType: "blob" });

      if (response.status !== 200) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const fileBlob = new Blob([response.data]);
      const fileUrlObj = URL.createObjectURL(fileBlob);
      const downloadLink = document.createElement("a");

      downloadLink.href = fileUrlObj;
      downloadLink.download = file.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
      URL.revokeObjectURL(fileUrlObj);
    } catch (error) {
      console.error("Error downloading single file:", error);
    }
  } else {
    const zip = new JSZip();

    try {
      await Promise.all(
        fileObjects.map(async (file) => {
          try {
            const response = await axios.get(file.url, { responseType: "blob" });

            if (response.status !== 200) {
              console.error(`Failed to download file ${file.name}: ${response.statusText}`);
              return;
            }

            zip.file(file.name, response.data);
          } catch (err) {
            console.error(`Error fetching file ${file.name}:`, err);
          }
        })
      );

      const zipBlob = await zip.generateAsync({ type: "blob" });

      saveAs(zipBlob, `${folderName}.zip`);
    } catch (error) {
      console.error("Error creating ZIP:", error);
    }
  }
}


export function filterArrayObjectsByValue(objects, key, search = "") {
  return objects.filter((object) => {
    const value = object[key] ? object[key].toLowerCase() : ""; // Convert value to lowercase if it exists, otherwise use an empty string
    return value.includes(search.toLowerCase()); // Use includes method for substring search
  });
}
